import {
  Answer,
  Choice,
  ChoiceQuestion,
  ChoiceResponse,
  createChoiceAnswer,
  isChoiceResponse,
} from "@cur8/questionnaire";
import React, { useCallback } from "react";
import styles from "./styles.module.sass";
import { Typography } from "render/ui/presentation/Typography";

interface GradeInputProps<T> {
  question: ChoiceQuestion<T, React.ReactNode>;
  answer?: Answer;
  onAnswer: (answer: Answer<ChoiceResponse>) => void;
}

export function GradeInput<T>({
  question,
  answer,
  onAnswer,
}: GradeInputProps<T>) {
  let pickedIndex: number;
  let selected: Choice<T> | undefined;

  if (answer && isChoiceResponse(answer.response)) {
    pickedIndex = answer.response.choiceIndex;
    selected = question.choices[answer.response.choiceIndex];
  }

  const handleChoice = useCallback(
    (choiceIndex: number) => {
      const answer = createChoiceAnswer(choiceIndex);
      onAnswer(answer);
    },
    [onAnswer]
  );

  return (
    <>
      <div className={styles.GradeInput}>
        {question.choices.map((_, choiceIndex) => {
          const classNames = [styles.choice];
          const isSelected = choiceIndex === pickedIndex;
          const isFirst = choiceIndex === 0;
          if (isSelected) {
            classNames.push(styles.picked);
          }

          return (
            <div
              key={choiceIndex}
              className={classNames.join(" ")}
              onClick={() => handleChoice(choiceIndex)}
            >
              {!isFirst && <div className={styles.separator} />}
              <Typography variant="body-label-m" as="span">
                {choiceIndex + 1}
              </Typography>
            </div>
          );
        })}
      </div>
      <div className={styles.description}>
        {selected && (
          <Typography variant="body-label-m">
            {selected.statement as unknown as React.ReactNode}
          </Typography>
        )}
      </div>
    </>
  );
}
