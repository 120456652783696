import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { InformationLayout } from "render/ui/layout/InformationLayout/InformationLayout";
import { Typography } from "render/ui/presentation/Typography";

export function MemberJoinedView() {
  return (
    <PageFrameContent>
      <InformationLayout
        content={
          <>
            <Typography variant="display-s">Member joined session</Typography>
            <Typography variant="body-m" color="subtle">
              Verifying booking...
            </Typography>
          </>
        }
      />
    </PageFrameContent>
  );
}
