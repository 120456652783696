import {
  Answer,
  MultiChoiceQuestion,
  MultiChoiceResponse,
  createMultiChoiceAnswer,
  isMultiChoiceResponse,
} from "@cur8/questionnaire";
import React, { useCallback, useMemo } from "react";
import { ReactComponent as CheckIcon } from "./assets/check.svg";
import styles from "./styles.module.sass";
import { Typography } from "render/ui/presentation/Typography";

interface MultiChoiceInputProps {
  question: MultiChoiceQuestion<unknown, React.ReactNode>;
  answer?: Answer;
  onAnswer: (answer: Answer<MultiChoiceResponse>) => void;
}

export function MultiChoiceInput({
  question,
  answer,
  onAnswer,
}: MultiChoiceInputProps) {
  const indices = useMemo(() => {
    const indices = [];
    if (answer && isMultiChoiceResponse(answer.response)) {
      indices.push(...answer.response.choiceIndices);
    }
    return new Set<number>(indices);
  }, [answer]);

  const handleChoice = useCallback(
    (choiceIndex: number) => {
      if (indices.has(choiceIndex)) {
        indices.delete(choiceIndex);
      } else {
        indices.add(choiceIndex);
      }

      const answer = createMultiChoiceAnswer([...indices]);

      onAnswer(answer);
    },
    [indices, onAnswer]
  );
  return (
    <div data-hj-suppress className={styles.MultiChoiceInput}>
      {question.choices.map((choice, choiceIndex) => {
        return (
          <label className={styles.input} key={choiceIndex}>
            <input
              className={styles.checkbox}
              type="checkbox"
              checked={indices.has(choiceIndex) ?? false}
              onChange={() => handleChoice(choiceIndex)}
            />
            <div className={styles.checkboxIcon}>
              <CheckIcon />
            </div>
            <Typography variant="body-label-m">{choice.statement}</Typography>
          </label>
        );
      })}
    </div>
  );
}
