import { InformationLayout } from "render/ui/layout/InformationLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { PageFrame } from "render/ui/layout/PageFrame";
import { PageFrameContent } from "render/ui/layout/PageFrameContent";
import { Typography } from "render/ui/presentation/Typography";

export function WakeUpPendingView() {
  return (
    <PageFrame head={<LogoHeader />}>
      <PageFrameContent>
        <InformationLayout
          content={<Typography variant="display-s">Initializing</Typography>}
        />
      </PageFrameContent>
    </PageFrame>
  );
}
