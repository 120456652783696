import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { PageFrame } from "render/ui/layout/PageFrame";
import { PageFrameContent } from "render/ui/layout/PageFrameContent";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { Typography } from "render/ui/presentation/Typography";

interface WakeUpOfferViewProps {
  onDecline(): void;
  onAccept(): void;
}

export function WakeUpOfferView({ onDecline, onAccept }: WakeUpOfferViewProps) {
  return (
    <PageFrame head={<LogoHeader />}>
      <PageFrameContent>
        <InformationLayout
          content={
            <Typography variant="display-s">Restore session?</Typography>
          }
          buttons={
            <ActionsFooter
              left={
                <ActionButton
                  variant="secondary"
                  direction="backward-centered"
                  onClick={onDecline}
                  hideIcon
                >
                  No, Reset
                </ActionButton>
              }
              right={
                <ActionButton
                  variant="suggestion"
                  direction="forward"
                  onClick={onAccept}
                >
                  Yes, Restore
                </ActionButton>
              }
            />
          }
        />
      </PageFrameContent>
    </PageFrame>
  );
}
