import { Visit } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { VisitStatus } from "lib/api/booking/visit";
import { useCallback } from "react";
import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { paths } from "render/routes/paths";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { Typography } from "render/ui/presentation/Typography";
import { AppointmentCard } from "render/ui/presentation/AppointmentCard";
import { useRoomQuery } from "hooks/queries/useRoomQuery";
import { usePatientQuery } from "hooks/queries/usePatientQuery";
import styles from "./styles.module.sass";

export interface OutOfRangeViewProps {
  status: VisitStatus;
  visit: Visit;
  onContinue(): Promise<unknown>;
}

export function OutOfRangeView({
  status,
  visit,
  onContinue,
}: OutOfRangeViewProps) {
  const { reset } = useConciergeSessionContext();

  const nav = {
    start: useNav(paths.root),
  };

  const handleRestart = useCallback(() => {
    nav.start.go({});
    reset();
  }, [nav.start, reset]);

  const handleContinue = useAsyncHandle(onContinue);

  const formattedVisitStart = visit.startTime.toFormat("HH:mm");

  const roomQuery = useRoomQuery({ roomId: visit?.roomId });
  const room = roomQuery.data;
  const patientQuery = usePatientQuery({ patientId: visit.patientId });
  const member = patientQuery.data;

  const fullName = `${member?.name?.firstName} ${member?.name?.lastName}`;
  const visitTime = `${formattedVisitStart} - ${visit?.endTime.toFormat(
    "HH:mm"
  )}`;
  const roomName = room?.displayName;
  const dob = member?.dateOfBirth?.toISODate();

  return (
    <PageFrameContent>
      <InformationLayout
        align="start"
        content={
          <section className={styles.layout}>
            <div className={styles.title}>
              {status === VisitStatus.TooEarly && (
                <>
                  <Typography variant="display-s">
                    Check in is too early
                  </Typography>
                  <Typography variant="body-m" color="subtle">
                    Member's appointment is at {formattedVisitStart}. Please ask
                    the member to come back later.
                  </Typography>
                </>
              )}

              {status === VisitStatus.Early && (
                <>
                  <Typography variant="display-s">Check in is early</Typography>
                  <Typography variant="body-m" color="subtle">
                    Member's appointment is at {formattedVisitStart}. Would you
                    like to continue the check in anyways?
                  </Typography>
                </>
              )}

              {status === VisitStatus.TooLate && (
                <>
                  <Typography variant="display-s">
                    Check in is too late
                  </Typography>
                  <Typography variant="body-m" color="subtle">
                    Member's appointment was at {formattedVisitStart}. Check in
                    is no longer possible.
                  </Typography>
                </>
              )}

              {status === VisitStatus.Late && (
                <>
                  <Typography variant="display-s">Check in is late</Typography>
                  <Typography variant="body-m" color="subtle">
                    Member's appointment was at {formattedVisitStart}. Would you
                    like to continue the check in anyways?
                  </Typography>
                </>
              )}
            </div>
            <AppointmentCard
              topLeft={fullName}
              topRight={visitTime}
              bottomLeft={dob}
              bottomRight={roomName}
            />
          </section>
        }
        buttons={
          <ActionsFooter
            left={
              <ActionButton
                variant="secondary"
                onClick={handleRestart}
                hideIcon
                direction="backward-centered"
              >
                Back
              </ActionButton>
            }
            right={
              <ActionButton
                variant="suggestion"
                disabled={
                  ![VisitStatus.Early, VisitStatus.Late].includes(status)
                }
                onClick={handleContinue.run}
                busy={handleContinue.busy}
              >
                Continue
              </ActionButton>
            }
          />
        }
      />
    </PageFrameContent>
  );
}
