import {
  RadioGroupProps,
  Radio as ReactAriaRadio,
  RadioGroup as ReactAriaRadioGroup,
} from "react-aria-components";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";

type RadioGroupPropsType = {
  children: React.ReactNode;
} & RadioGroupProps;

export function RadioGroup({ children, ...props }: RadioGroupPropsType) {
  return (
    <ReactAriaRadioGroup {...props} className={styles.RadioGroup}>
      {children}
    </ReactAriaRadioGroup>
  );
}

RadioGroup.Radio = Radio;

function Radio({
  value,
  label,
  subline,
  metadata,
}: {
  value: string;
  label: React.ReactNode;
  subline?: React.ReactNode;
  metadata?: React.ReactNode;
}) {
  return (
    <ReactAriaRadio value={value} className={styles.Radio}>
      <div className={styles.checkboxIcon} />
      <div className={styles.textWrapper}>
        <div className={styles.textRowWrapper}>
          <Typography variant="body-label-m">{label}</Typography>
          {metadata ? (
            <Typography variant="body-m" color="subtle" whiteSpace="nowrap">
              {metadata}
            </Typography>
          ) : undefined}
        </div>
        {subline ? (
          <Typography variant="body-s" color="subtle">
            {subline}
          </Typography>
        ) : undefined}
      </div>
    </ReactAriaRadio>
  );
}
