import React, { useMemo } from "react";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { ActionButton } from "render/ui/trigger/ActionButton";
import styles from "./styles.module.sass";
import { Typography } from "render/ui/presentation/Typography";

interface StepViewProps {
  caption: React.ReactNode;
  children: React.ReactNode;
  cta?: React.ReactNode;
  onPrev?: () => void;
}

export function StepView({ cta, caption, children, onPrev }: StepViewProps) {
  const nav = useMemo(
    () => (
      <div className={styles.nav}>
        <ActionsFooter
          left={
            onPrev ? (
              <ActionButton
                variant="secondary"
                direction="backward-centered"
                onClick={onPrev}
                hideIcon
              >
                Back
              </ActionButton>
            ) : null
          }
          right={cta}
        />
      </div>
    ),
    [cta, onPrev]
  );
  return (
    <div className={styles.Step}>
      <Typography variant="display-s">{caption}</Typography>
      {children}
      {nav}
    </div>
  );
}
