import { record, string } from "@pomle/shapes";
import Cookies from "js-cookie";

export const NEKO_COOKIES = {
  settings: {
    location: {
      v1: "neko_settings_location_1",
    },
  },
};

export type LocationType = {
  value: string;
  label: string;
  subline: string;
  metadata: string;
};

export function getAndParseLocationCookie() {
  return parseLocationCookie(Cookies.get(NEKO_COOKIES.settings.location.v1));
}

function parseLocationCookie(
  cookieValue: string | undefined
): LocationType | undefined {
  try {
    return cookieValue ? validate(JSON.parse(cookieValue)) : undefined;
  } catch (error) {
    console.error("Error parsing or validating the location cookie", error);
    return undefined;
  }
}

const validate = record<LocationType>({
  value: string(""),
  label: string(""),
  subline: string(""),
  metadata: string(""),
});
