import { useLatestConsentsQuery } from "hooks/queries/usePatientConsentsQuery";
import { ImmediateRiskAssessment } from "lib/types/immediate-risk-assessment";
import { useCallback, useState } from "react";
import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { paths } from "render/routes/paths";
import { ActionsFooter } from "render/ui/layout/ActionsFooter/ActionsFooter";
import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { BinaryRadio } from "render/ui/presentation/BinaryRadio";
import { Group, getGroupHighlight } from "render/ui/presentation/Group";
import { ActionButton } from "render/ui/trigger/ActionButton/ActionButton";
import { BailView } from "../BailView/BailView";
import styles from "./styles.module.sass";
import { Trans } from "./trans";
import { useImmediateRiskMutation } from "hooks/mutations/useImmediateRiskMutation";
import { Typography } from "render/ui/presentation/Typography";
import { InformationLayout } from "render/ui/layout/InformationLayout";

interface ImmediateRiskAssessmentProps {
  goTo(url: string): void;
  onBack(): void;
}

const DEFAULT_ERROR_MESSAGE = "Please answer this question";

type Errors = {
  [key in keyof ImmediateRiskAssessment]: string | undefined;
};

const validate = (risk: ImmediateRiskAssessment) => {
  const newErrors = {} as Errors;

  if (risk.acuteSymptoms === undefined) {
    newErrors.acuteSymptoms = DEFAULT_ERROR_MESSAGE;
  }

  if (risk.eyeSurgery === undefined) {
    newErrors.eyeSurgery = DEFAULT_ERROR_MESSAGE;
  }

  if (risk.medicationUsageProneToBleeding === undefined) {
    newErrors.medicationUsageProneToBleeding = DEFAULT_ERROR_MESSAGE;
  }

  return newErrors;
};

const INITIAL_RISK_ASSESSMENT: ImmediateRiskAssessment = {
  acuteSymptoms: undefined,
  eyeSurgery: undefined,
  medicationUsageProneToBleeding: undefined,
};

export function ImmediateRiskAssessmentView({
  goTo,
  onBack,
}: ImmediateRiskAssessmentProps) {
  const {
    state: { patient, visit },
  } = useConciergeSessionContext();
  const [risk, setRisk] = useState<ImmediateRiskAssessment>(
    INITIAL_RISK_ASSESSMENT
  );
  const [errors, setErrors] = useState<Errors>();
  const { mutate } = useImmediateRiskMutation();

  const { data: latestConsents } = useLatestConsentsQuery({
    patientId: patient?.patientId,
  });

  const hasCounterSignRequiredConsents =
    latestConsents?.find(
      (consent) => consent.relevantSignature.counterSignatureRequired === true
    ) != null;

  const handleContinue = useAsyncHandle(
    useCallback(async () => {
      if (!risk) {
        return;
      }

      const errors = validate(risk);
      const hasErrors = Object.keys(errors).length > 0;

      if (hasErrors) {
        setErrors(errors);
        return;
      }

      mutate({
        patientId: patient?.patientId,
        visitId: visit?.visitId,
        assessment: risk,
      });

      const url = hasCounterSignRequiredConsents
        ? paths.counterSign.url({})
        : paths.assignToRoom.url({});
      goTo(url);
    }, [
      goTo,
      hasCounterSignRequiredConsents,
      mutate,
      patient?.patientId,
      risk,
      visit?.visitId,
    ])
  );

  if (!patient) {
    return <BailView message="No member selected" />;
  }

  return (
    <PageFrameContent>
      <InformationLayout
        content={
          <section className={styles.layout}>
            <Typography variant="display-s">
              <Trans.Title />
            </Typography>

            <div className={styles.questions}>
              <Group
                highlight={getGroupHighlight({
                  value: risk?.acuteSymptoms,
                  hasError: Boolean(errors?.acuteSymptoms),
                })}
              >
                <BinaryRadio
                  name="immediate_risk_assessment.accuteSymptoms"
                  label="Does the member have acute symptoms today?"
                  value={risk?.acuteSymptoms}
                  error={errors?.acuteSymptoms}
                  onChange={(value) => {
                    setRisk((prev) => ({ ...prev, acuteSymptoms: value }));
                    if (errors) {
                      delete errors["acuteSymptoms"];
                    }
                  }}
                />
              </Group>
              <Group
                highlight={getGroupHighlight({
                  value: risk?.eyeSurgery,
                  hasError: Boolean(errors?.eyeSurgery),
                })}
              >
                <BinaryRadio
                  name="immediate_risk_assessment.eyeSurgery"
                  label="Eye surgery performed in the last week?"
                  value={risk?.eyeSurgery}
                  error={errors?.eyeSurgery}
                  onChange={(value) => {
                    setRisk((prev) => ({ ...prev, eyeSurgery: value }));
                    if (errors) {
                      delete errors["eyeSurgery"];
                    }
                  }}
                />
              </Group>

              <Group
                highlight={getGroupHighlight({
                  value: risk?.medicationUsageProneToBleeding,
                  hasError: Boolean(errors?.medicationUsageProneToBleeding),
                })}
              >
                <BinaryRadio
                  name="immediate_risk_assessment.medicationUsageProneToBleeding"
                  label="Medication usage predisposing to bleeding? "
                  value={risk?.medicationUsageProneToBleeding}
                  error={errors?.medicationUsageProneToBleeding}
                  onChange={(value) => {
                    setRisk((prev) => ({
                      ...prev,
                      medicationUsageProneToBleeding: value,
                    }));
                    if (errors) {
                      delete errors["medicationUsageProneToBleeding"];
                    }
                  }}
                />
              </Group>
            </div>
          </section>
        }
        buttons={
          <ActionsFooter
            left={
              <ActionButton
                variant="secondary"
                direction="backward-centered"
                onClick={onBack}
                hideIcon
              >
                Back
              </ActionButton>
            }
            right={
              <ActionButton
                variant="suggestion"
                busy={handleContinue.busy}
                onClick={handleContinue.run}
              >
                Continue
              </ActionButton>
            }
          />
        }
      />
    </PageFrameContent>
  );
}
