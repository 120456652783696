import { SessionState, useMSAL } from "render/context/MSALContext";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { PageFrame } from "render/ui/layout/PageFrame";
import { PageFrameContent } from "render/ui/layout/PageFrameContent";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { Typography } from "render/ui/presentation/Typography";

export function LoginView() {
  const { auth, session } = useMSAL();

  const busy = session.state !== SessionState.Settled;

  const canLogin = !auth && !busy;

  return (
    <PageFrame head={<LogoHeader />}>
      <PageFrameContent>
        <InformationLayout
          content={
            <Typography variant="display-s" color="default">
              Please sign in to start
            </Typography>
          }
          buttons={
            <ActionsFooter
              right={
                <ActionButton
                  onClick={session.logIn}
                  disabled={!canLogin}
                  busy={!canLogin}
                  variant="suggestion"
                >
                  Sign in
                </ActionButton>
              }
            />
          }
        />
      </PageFrameContent>
    </PageFrame>
  );
}
