import {
  Link as ReactAriaLink,
  LinkProps as ReactAriaLinkProps,
} from "react-aria-components";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";

type LinkProps = {
  children: React.ReactNode;
} & Omit<ReactAriaLinkProps, "children">;

export function Link(props: LinkProps) {
  return (
    <ReactAriaLink {...props} className={styles.Link}>
      <Typography variant={"body-link-m"}>{props.children}</Typography>
    </ReactAriaLink>
  );
}
