import { APIFetchError } from "@cur8/api-client";
import { fromAPI } from "@cur8/rich-entity";
import { useCallback, useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useAPIClient } from "render/context/APIContext";
import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { Poll } from "render/hooks/effects";
import { useReporting } from "render/hooks/useReporting";
import { paths } from "render/routes/paths";
import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { BailView } from "../BailView/BailView";
import { ReactComponent as QRCodeIcon } from "./assets/show-qrcode.svg";
import { useQRCodeURL } from "./hooks/useQRCodeURL";
import Cookies from "js-cookie";
import styles from "./styles.module.sass";
import { Typography } from "render/ui/presentation/Typography";
import { getAndParseLocationCookie, NEKO_COOKIES } from "lib/nekoCookies";
import type { AuthMethod } from "render/views/JoinSessionView/token";
import { LoginOption } from "render/views/JoinSessionView/components/LoginOption";
import { Link } from "render/ui/trigger/Link";

interface JoinSessionViewProps {
  goTo(url: string): void;
}

const authMethods: { id: AuthMethod; description: string }[] = [
  { id: "bankid", description: "BankID" },
  { id: "phoneauth", description: "Phone authentication" },
];

export function JoinSessionView({ goTo }: JoinSessionViewProps) {
  const { state, update } = useConciergeSessionContext();

  const { session } = state;

  const api = useAPIClient();

  const [error, setError] = useState<Error>();

  const { logError } = useReporting();

  const parsedLocation = getAndParseLocationCookie();
  const [authMethod, setAuthMethod] = useState<AuthMethod>(
    parsedLocation?.value.startsWith("SE-") ? "bankid" : "phoneauth"
  );

  useEffect(() => {
    if (parsedLocation == null) {
      goTo(paths.locationSelector.url({}));
      return;
    }

    Cookies.set(
      NEKO_COOKIES.settings.location.v1,
      JSON.stringify(parsedLocation),
      { expires: 400 }
    );
  }, [goTo, parsedLocation]);

  const goToNext = useCallback(() => {
    const url = paths.assignSlot.url({});
    goTo(url);
  }, [goTo]);

  const readPatientFromSession = useCallback(
    async (sessionId: string) => {
      const session = await api.checkinSession
        .fetchSession({ sessionId })
        .result.then(fromAPI.toCheckinSessionState)
        .catch((error: unknown) => {
          if (error instanceof APIFetchError) {
            if (error.response.status === 404) {
              throw new SessionGoneError(error.message);
            }
          }

          throw error;
        });

      if (!session.patientId) {
        return null;
      }

      const patient = await api.patient
        .fetchPatient({ patientId: session.patientId })
        .result.then(fromAPI.toPatientDetails);

      return { patient, session };
    },
    [api]
  );

  const patient = state.patient;

  useEffect(() => {
    if (patient) {
      return;
    }

    if (!session) {
      return;
    }

    return Poll.run(async () => {
      try {
        const response = await readPatientFromSession(session.sessionId);
        if (response) {
          const { patient, session } = response;
          update({ patient, session });
          goToNext();
          return Poll.STOP;
        }
      } catch (error: unknown) {
        if (error instanceof SessionGoneError) {
          setError(error);
          return Poll.STOP;
        } else {
          throw error;
        }
      }
    }, POLL_BACKOFF_TIME);
  }, [patient, session, readPatientFromSession, update, goToNext]);

  const startCheckIn = useCallback(async () => {
    const seed = createSeed();

    await api.checkinSession
      .createSession({ seed })
      .result.then(fromAPI.toCheckinSessionState)
      .then((session) => {
        update({ session });
      })
      .catch(logError);
  }, [api.checkinSession, logError, update]);

  const patientDeviceURL = useQRCodeURL(authMethod);

  if (error) {
    if (error instanceof SessionGoneError) {
      return <BailView message="Session gone" />;
    }

    return <BailView message={error.message} />;
  }

  return (
    <PageFrameContent>
      <div className={styles.JoinSessionView}>
        <section className={styles.intro}>
          <h1 className={styles.location}>
            {parsedLocation && parsedLocation.label}
          </h1>
          <div className={styles.paddingContainer}>
            <h2 className={styles.title}>Neko Health</h2>
            <Typography variant="title-s" color="subtle">
              Please point your phone camera at the QR code to check-in.
            </Typography>
          </div>
        </section>

        <section>
          <div className={styles.linksLayout}>
            <div className={styles.qrCode} data-url={patientDeviceURL}>
              <div
                className={styles.blur}
                data-visible={!session}
                onClick={startCheckIn}
              >
                <div className={styles.showcta}>
                  <QRCodeIcon height={40} width={40} />
                  <Typography variant="body-label-m" as="span">
                    Show code
                  </Typography>
                </div>
              </div>
              <div className={styles.frame}>
                <QRCode
                  value={patientDeviceURL ?? FAKE_QR_ENTROPY}
                  size={228}
                />
              </div>
              <form
                className={styles.form}
                hidden={!session}
                onChange={(event) => {
                  const { elements } = event.currentTarget;
                  const el = elements.namedItem("authmethod") as RadioNodeList;
                  setAuthMethod(el.value as AuthMethod);
                }}
              >
                {authMethods.map(({ id, description }) => (
                  <LoginOption
                    key={id}
                    name="authmethod"
                    value={id}
                    defaultChecked={id === authMethod}
                  >
                    {description}
                  </LoginOption>
                ))}
              </form>
            </div>
            <Link onPress={() => goTo(paths.selectVisit.url({}))}>
              <Typography variant="body-link-m">Manual check-in</Typography>
            </Link>
          </div>
          {patient && (
            <div className={styles.cta}>
              <ActionButton onClick={goToNext}>Continue</ActionButton>
            </div>
          )}
        </section>
      </div>
    </PageFrameContent>
  );
}

const FAKE_QR_ENTROPY = "0129841902481=24g9182418=4gk190gk190gj8m";

const POLL_BACKOFF_TIME = 2000;

function createSeed() {
  return (Math.random() * Number.MAX_SAFE_INTEGER).toFixed();
}

class SessionGoneError extends Error {}
