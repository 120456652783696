import { useNav } from "@pomle/react-router-paths";
import React, { useCallback } from "react";
import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { paths } from "render/routes/paths";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { Typography } from "render/ui/presentation/Typography";

const DEFAULT_TITLE = "Error";
const DEFAULT_MESSAGE = "An exception has occurred. Please go back to start.";

interface BailViewProps {
  title?: React.ReactNode;
  message?: React.ReactNode;
}

export function BailView({
  title = DEFAULT_TITLE,
  message = DEFAULT_MESSAGE,
}: BailViewProps) {
  const { reset } = useConciergeSessionContext();

  const nav = {
    start: useNav(paths.root),
  };

  const handleRestart = useCallback(() => {
    nav.start.go({});
    reset();
  }, [nav.start, reset]);

  return (
    <PageFrameContent>
      <InformationLayout
        align="start"
        content={
          <>
            <Typography variant="display-s">{title}</Typography>
            <Typography variant="body-m" color="subtle">
              {message}
            </Typography>
          </>
        }
        buttons={
          <ActionsFooter
            left={
              <ActionButton
                variant="secondary"
                direction="backward-centered"
                onClick={handleRestart}
                hideIcon
              >
                Back
              </ActionButton>
            }
            right={undefined}
          />
        }
      />
    </PageFrameContent>
  );
}
