import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { BailView } from "render/views/BailView";
import { Typography } from "render/ui/presentation/Typography";
import { List } from "render/ui/presentation/List";
import styles from "./styles.module.sass";
import { useState } from "react";
import { SpinningLoader } from "render/ui/presentation/SpinningLoader";
import { ReactComponent as Checkmark } from "render/assets/icons/96x96_check.svg";
import { paths } from "render/routes/paths";

interface AssignToSkinScanner {
  goTo(url: string): void;
}

export function AssignToSkinScanner({ goTo }: AssignToSkinScanner) {
  const {
    state: { patient },
  } = useConciergeSessionContext();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  if (!patient) {
    return <BailView title="No Member" />;
  }

  return (
    <PageFrameContent>
      <section className={styles.base}>
        <section
          className={styles.transition}
          data-active={!loading && !error && !success}
        >
          <InformationLayout
            content={
              <section className={styles.layout}>
                <Typography variant="display-s">
                  Assign {patient.name?.displayName} to the skin scanner when
                  they are ready
                </Typography>
                <List>
                  <List.Item>
                    <div className={styles.item}>
                      <Typography variant="title-m">Skin scanner</Typography>
                      <StatusBadge error={false} label="Free" />
                    </div>
                  </List.Item>
                </List>
              </section>
            }
            buttons={
              <ActionsFooter
                right={
                  <ActionButton
                    variant="suggestion"
                    disabled={false}
                    onClick={() => {
                      setLoading(true);
                      setTimeout(() => {
                        setError(true);
                        setLoading(false);
                      }, 2000);
                    }}
                  >
                    Assign to skin scanner
                  </ActionButton>
                }
              />
            }
          />
        </section>
        <section className={styles.transition} data-active={loading || success}>
          <div className={styles.loader}>
            <SpinningLoader success={success} />
            <div className={styles.loaderText} data-active={success}>
              <Typography variant="title-s">Member assigned</Typography>
              <Typography variant="title-s">to skin scanner</Typography>
            </div>
            <div className={styles.checkmark} data-active={success}>
              <Checkmark />
            </div>
          </div>
        </section>
        <section className={styles.transition} data-active={error} data-bottom>
          <InformationLayout
            content={
              <section className={styles.title}>
                <Typography variant="display-s">
                  Couldn't assign member to the skin scanner
                </Typography>
                <Typography variant="body-m" color="subtle">
                  Please try again, or cancel and proceed with the other
                  examinations
                </Typography>
              </section>
            }
            buttons={
              <ActionsFooter
                left={
                  <ActionButton
                    variant="secondary"
                    direction="backward-centered"
                    onClick={() => {
                      goTo(paths.checkInSuccess.url({}));
                    }}
                    hideIcon
                  >
                    Cancel
                  </ActionButton>
                }
                right={
                  <ActionButton
                    variant="suggestion"
                    disabled={false}
                    onClick={() => {
                      setLoading(true);
                      setError(false);
                      setTimeout(() => {
                        setSuccess(true);
                        setLoading(false);
                      }, 2000);
                      setTimeout(() => {
                        goTo(paths.checkInSuccess.url({}));
                      }, 5000);
                    }}
                  >
                    Assign to skin scanner
                  </ActionButton>
                }
              />
            }
          />
        </section>
      </section>
    </PageFrameContent>
  );
}

function StatusBadge({ error, label }: StatusBadgeProps) {
  return (
    <div className={styles.status} data-error={error}>
      <Typography variant="eyebrow-m" as="span">
        {label}
      </Typography>
    </div>
  );
}

type StatusBadgeProps = {
  error: boolean | undefined;
  label: string;
};
