import { fromAPI } from "@cur8/rich-entity";
import { useFinishCheckInMutation } from "hooks/mutations/useFinishCheckInMutation";
import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { paths } from "render/routes/paths";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { BailView } from "render/views/BailView";
import { Typography } from "render/ui/presentation/Typography";
import { useRoomQuery } from "hooks/queries/useRoomQuery";
import { Flags, FLAGS } from "lib/flags";

interface AssignToRoomProps {
  goTo(url: string): void;
}

export function AssignToRoomView({ goTo }: AssignToRoomProps) {
  const {
    state: { patient, visit },
  } = useConciergeSessionContext();

  const roomQuery = useRoomQuery({ roomId: visit?.roomId });
  const roomName = roomQuery.data?.displayName;

  const finishCheckIn = useFinishCheckInMutation();
  const { update } = useConciergeSessionContext();

  const nextUrl = FLAGS.has(Flags.AssignSkinScanner)
    ? paths.assignToSkinScanner.url({})
    : paths.checkInSuccess.url({});

  if (!patient) {
    return <BailView title="No Member" />;
  }

  if (!visit) {
    return <BailView title="No Visit object" />;
  }

  return (
    <PageFrameContent>
      <InformationLayout
        content={
          <>
            <Typography variant="display-s">Are you in {roomName}?</Typography>
            <Typography variant="body-m" color="subtle">
              When {patient.name?.displayName || "the member"} is ready to start
              the scan, assign them to the room.
            </Typography>
          </>
        }
        buttons={
          <ActionsFooter
            right={
              <ActionButton
                variant="suggestion"
                busy={finishCheckIn.isPending}
                onClick={async () => {
                  if (finishCheckIn.isPending || visit.checkinEndTimestamp)
                    return;
                  const v = await finishCheckIn.mutateAsync(visit);
                  update({ visit: fromAPI.toVisit(v) });
                  goTo(nextUrl);
                }}
              >
                Yes, assign to room
              </ActionButton>
            }
          />
        }
      />
    </PageFrameContent>
  );
}
