import { Path, PathCodec } from "@pomle/paths";
import { AssignToRoomView } from "render/views/AssignToRoom";
import { ChecklistView } from "render/views/ChecklistView";
import { CheckInCompleteView } from "render/views/CheckInCompleteView";
import { CheckOutView } from "render/views/CheckOutView";
import { CounterSignView } from "render/views/CounterSignView";
import { ImmediateRiskAssessmentView } from "render/views/ImmediateRiskAssessmentView";
import { IncidentReportView } from "render/views/IncidentReportView";
import { JoinSessionView } from "render/views/JoinSessionView";
import { MedicalJournalConsentView } from "render/views/MedicalJournalConsent";
import { PreferredNameView } from "render/views/PreferredNameView";
import { SessionJoinCompleteView } from "render/views/SessionJoinCompleteView";
import { paths } from "./paths";
import { LocationSelectorView } from "render/views/LocationSelector";
import { SelectVisitView } from "render/views/SelectVisit";
import { SelectVisitConfirmView } from "render/views/SelectVisitConfirm";
import { AssignToSkinScanner } from "render/views/AssignToSkinScanner";

function createStep<Codec extends PathCodec>(
  id: string,
  path: Path<Codec>,
  component: (
    params: ReturnType<Path<Codec>["decode"]>,
    options: { goTo: (url: string) => void; onBack: () => void }
  ) => React.ReactElement | null
) {
  return {
    id,
    path,
    component,
  };
}

export const steps = [
  createStep(
    "fe64c95d-dcb4-4d50-8018-1631c345ef3c",
    paths.locationSelector,
    (_, { goTo }) => {
      return <LocationSelectorView goTo={goTo} />;
    }
  ),

  createStep(
    "48316e68-66f4-11ee-a533-00155db6b9f6",
    paths.root,
    (_, { goTo }) => {
      return <JoinSessionView goTo={goTo} />;
    }
  ),

  createStep(
    "8b1584aa-5d01-41a0-a4a1-ea842c6b99ea",
    paths.selectVisit,
    (_, { goTo, onBack }) => {
      return <SelectVisitView goTo={goTo} onBack={onBack} />;
    }
  ),

  createStep(
    "4c4566e5-213c-46b4-883c-3f38e99440ab",
    paths.selectVisitConfirm,
    (params, { goTo, onBack }) => {
      return (
        <SelectVisitConfirmView
          goTo={goTo}
          onBack={onBack}
          patientId={params.patientId}
          visitId={params.visitId}
        />
      );
    }
  ),

  createStep(
    "6090e7f4-66f4-11ee-8c44-00155db6b9f6",
    paths.assignSlot,
    (_, { goTo, onBack }) => {
      return <SessionJoinCompleteView goTo={goTo} onBack={onBack} />;
    }
  ),

  createStep(
    "6654e21c-66f4-11ee-98e4-00155db6b9f6",
    paths.awaitingConsent,
    (_, { goTo, onBack }) => {
      return <ChecklistView goTo={goTo} onBack={onBack} />;
    }
  ),

  createStep(
    "32c57e72-34d2-4ea8-bc4c-47cb84b55a7b",
    paths.medicalJournalConsent,
    (_, { goTo, onBack }) => {
      return <MedicalJournalConsentView goTo={goTo} onBack={onBack} />;
    }
  ),

  createStep(
    "738274d6-66f4-11ee-9199-00155db6b9f6",
    paths.preferredName,
    (_, { goTo, onBack }) => {
      return <PreferredNameView goTo={goTo} onBack={onBack} />;
    }
  ),

  createStep(
    "36a11b4e-97g4-41ee-3030-00118db3b9f`",
    paths.immediateRiskAssessment,
    (_, { goTo, onBack }) => {
      return <ImmediateRiskAssessmentView goTo={goTo} onBack={onBack} />;
    }
  ),

  createStep(
    "6ca73322-66f4-11ee-b079-00155db6b9f6",
    paths.counterSign,
    (_, { goTo, onBack }) => {
      return <CounterSignView goTo={goTo} onBack={onBack} />;
    }
  ),

  createStep(
    "9214fbb7-cf2d-4fa4-a5c3-805902d036da",
    paths.assignToRoom,
    (_, { goTo }) => {
      return <AssignToRoomView goTo={goTo} />;
    }
  ),

  createStep(
    "0ca071f9-aaeb-4c39-b29e-badfb416d010",
    paths.assignToSkinScanner,
    (_, { goTo }) => {
      return <AssignToSkinScanner goTo={goTo} />;
    }
  ),

  createStep(
    "96a46898-66f4-11ee-8bdd-00155db6b9f6",
    paths.checkInSuccess,
    (_, { goTo }) => {
      return <CheckInCompleteView goTo={goTo} />;
    }
  ),

  createStep(
    "a3290b96-66f4-11ee-ab4b-00155db6b9f6",
    paths.checkOut,
    (_, { goTo }) => {
      return <CheckOutView goTo={goTo} />;
    }
  ),

  createStep(
    "9be70630-66f4-11ee-a206-00155db6b9f6",
    paths.incidentReport,
    (params, { goTo }) => {
      return <IncidentReportView activeStep={params.activeStep} goTo={goTo} />;
    }
  ),
];

export function findStep(pathname: string) {
  return steps.find((step) => {
    // Look for exact match
    return step.path.match(pathname) === 0;
  });
}
