import { fromAPI } from "@cur8/rich-entity";
import { skipToken, useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";

export function useRoomQuery({ roomId }: { roomId: string | undefined }) {
  const apiClient = useAPIClient();

  return useQuery({
    queryKey: queryKey(roomId),
    queryFn: roomId ? queryFn(apiClient, roomId) : skipToken,
    staleTime: Infinity,
  });
}

const queryKey = (roomId: string | undefined) => ["room", roomId];
const queryFn = (apiClient: APIClient, roomId: string) => async () => {
  const roomDTO = await apiClient.schedule.fetchRoom({ roomId }).result;
  const result = fromAPI.toRoom(roomDTO);

  return result;
};
